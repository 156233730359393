import uniqBy from 'lodash/uniqBy';

import type { MergeFunction } from '../MergeFunction';
import type { LocalActivityQuery_localActivity as LocalActivityEvent } from '../__types__/LocalActivityQuery';

import type { LocalActivityMyVisitsQuery_activities_myActivities_viewed_edges as MyVisitsEdge } from './__types__/MyVisitsQuery';

export const mergeMyVisits: MergeFunction<MyVisitsEdge> = (
	remoteEdges,
	localActivity,
	{ expirationSeconds },
) => {
	const mergedEdges: MyVisitsEdge[] = [];
	let latestExpiration: Date | undefined;

	const processedRemoteEventContentIds = new Set<string>();
	const consistentContentIds = new Set<string>();

	const pushIfNotDeleted = (edge: MyVisitsEdge) => {
		if (!localActivity.deletedContentIds.has(edge.node?.object?.content?.id || '')) {
			mergedEdges.push(edge);
		}
	};

	const expirationThreshold = secondsAgo(expirationSeconds);
	const processAndPushLocalEvent = (localEvent: LocalActivityEvent) => {
		pushIfNotDeleted(toMyVisitsEdge(localEvent));

		const localEventTime = new Date(localEvent.timestamp);
		if (!latestExpiration && localEventTime <= expirationThreshold) {
			latestExpiration = localEventTime;
			latestExpiration.setSeconds(latestExpiration.getSeconds() + expirationSeconds);
		}

		// If a remote event with the same content ID has already been processed, we know the event is consistent
		const contentId = localEvent.content.id || '';
		if (processedRemoteEventContentIds.has(contentId)) {
			consistentContentIds.add(contentId);
		}
	};

	const processAndPushRemoteEvent = (remoteEdge: MyVisitsEdge) => {
		pushIfNotDeleted(remoteEdge);
		processedRemoteEventContentIds.add(remoteEdge.node?.object?.content?.id || '');
	};

	let remoteIndex = 0;
	let localIndex = 0;

	// Iterate remote and local viewed events at the same time, pushing to
	// mergedEdges whichever edge has a newer timestamp (if it hasn't been deleted)
	while (remoteIndex < remoteEdges.length || localIndex < localActivity.viewedEvents.length) {
		const remoteEdge = remoteEdges[remoteIndex];
		const localEvent = localActivity.viewedEvents[localIndex];
		if (!localEvent) {
			processAndPushRemoteEvent(remoteEdge);
			remoteIndex++;
		} else if (!remoteEdge) {
			processAndPushLocalEvent(localEvent);
			localIndex++;
		} else {
			const remoteTimestamp = remoteEdge?.node?.timestamp;
			const localTimestamp = localEvent.timestamp;
			if (!remoteTimestamp || new Date(localTimestamp) >= new Date(remoteTimestamp)) {
				processAndPushLocalEvent(localEvent);
				localIndex++;
			} else {
				processAndPushRemoteEvent(remoteEdge);
				remoteIndex++;
			}
		}
	}

	return {
		mergedNodes: uniqBy(mergedEdges, (edge) => edge.node?.object?.content?.id),
		latestExpiration,
		consistentContentIds,
	};
};

const toMyVisitsEdge = (event: LocalActivityEvent): MyVisitsEdge => {
	const id = event.content.id || '';
	return {
		cursor: '',
		node: {
			id,
			timestamp: event.timestamp,
			object: {
				id,
				name: event.content.title,
				localResourceId: id,
				content: event.content,
			},
		},
	};
};

const secondsAgo = (n: number) => {
	const date = new Date();
	date.setSeconds(date.getSeconds() - n);
	return date;
};

import gql from 'graphql-tag';

export const LocalActivityContentFragment = gql`
	fragment LocalActivityContentFragment on Content {
		id
		title
		hasRestrictions
		hasInheritedRestrictions
		space {
			id
			key
		}
		properties(keys: ["emoji-title-published", "emoji-title-draft"]) {
			nodes {
				id
				value
				key
				version {
					number
				}
			}
		}
	}
`;

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum LocalActivityAction {
  deleted = "deleted",
  viewed = "viewed",
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LocalActivityQuery
// ====================================================

export interface LocalActivityQuery_localActivity_content_space {
  id: string | null;
  key: string | null;
}

export interface LocalActivityQuery_localActivity_content_properties_nodes_version {
  number: number | null;
}

export interface LocalActivityQuery_localActivity_content_properties_nodes {
  id: string | null;
  value: string | null;
  key: string | null;
  version: LocalActivityQuery_localActivity_content_properties_nodes_version | null;
}

export interface LocalActivityQuery_localActivity_content_properties {
  nodes: (LocalActivityQuery_localActivity_content_properties_nodes | null)[] | null;
}

export interface LocalActivityQuery_localActivity_content {
  id: string | null;
  title: string | null;
  hasRestrictions: boolean;
  hasInheritedRestrictions: boolean;
  space: LocalActivityQuery_localActivity_content_space | null;
  properties: LocalActivityQuery_localActivity_content_properties | null;
  __typename: "Content";
}

export interface LocalActivityQuery_localActivity {
  id: string;
  action: LocalActivityAction;
  timestamp: string;
  content: LocalActivityQuery_localActivity_content;
  __typename: "LocalActivityEvent";
}

export interface LocalActivityQuery {
  localActivity: LocalActivityQuery_localActivity[];
}

import gql from 'graphql-tag';

import { LocalActivityContentFragment } from './LocalActivityContentFragment.fragment';

export const LocalActivityQuery = gql`
	query LocalActivityQuery {
		localActivity {
			id
			action
			timestamp
			content {
				...LocalActivityContentFragment
				__typename
			}
			__typename
		}
	}
	${LocalActivityContentFragment}
`;

export const DehydratedLocalActivityQuery = gql`
	query DehydratedLocalActivityQuery {
		localActivity {
			id
			action
			timestamp
			content {
				id
				__typename
			}
			__typename
		}
	}
`;

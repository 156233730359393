import gql from 'graphql-tag';

import { LocalActivityContentFragment } from '../LocalActivityContentFragment.fragment';

export const MyVisitsQuery = gql`
	query LocalActivityMyVisitsQuery($cloudId: ID!, $first: Int!, $after: String, $spaceId: ID!) {
		activities {
			myActivities {
				viewed(
					filters: [
						{
							type: AND
							arguments: {
								products: [CONFLUENCE]
								objectTypes: [PAGE]
								cloudIds: [$cloudId]
								containerIds: [$spaceId]
							}
						}
					]
					first: $first
					after: $after
				) {
					edges {
						node {
							id
							timestamp
							object {
								id
								localResourceId
								name
								content {
									title
									...LocalActivityContentFragment
								}
							}
						}
						cursor
					}
					pageInfo {
						hasNextPage
					}
				}
			}
		}
	}
	${LocalActivityContentFragment}
`;

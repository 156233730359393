import { useCallback } from 'react';
import { useQuery } from 'react-apollo';
import type ApolloClient from 'apollo-client';

import { DehydratedLocalActivityQuery, LocalActivityQuery } from './LocalActivityQueries.graphql';
import type {
	DehydratedLocalActivityQuery as DehydratedResult,
	DehydratedLocalActivityQuery_localActivity as DehydratedEvent,
} from './__types__/DehydratedLocalActivityQuery';
import type {
	LocalActivityQuery as HydratedResult,
	LocalActivityQuery_localActivity as LocalActivityEvent,
} from './__types__/LocalActivityQuery';
import { LocalActivityAction } from './__types__/LocalActivityQuery';

const MAX_EVENTS = 100;

export type LocalActivity = {
	viewedEvents: LocalActivityEvent[];
	deletedContentIds: Set<string>;
	markAsConsistent: (contentId: string) => void;
};

const contentIdsToBeEvicted = new Set<string>();
const isMarkedForEviction = (event: DehydratedEvent) =>
	contentIdsToBeEvicted.has(event.content.id || '');

type LocalActivityFilters = {
	spaceKey?: string;
};

type UseLocalActivityOptions = {
	filters?: LocalActivityFilters;
	skip?: boolean;
};

export const useLocalActivity = ({
	filters,
	skip,
}: UseLocalActivityOptions = {}): LocalActivity => {
	const { data } = useQuery<HydratedResult>(LocalActivityQuery, {
		fetchPolicy: 'cache-only',
		skip,
		returnPartialData: true,
	});

	/**
	 * Events which are marked as consistent for all backend APIs can safely be evicted.
	 * At the time of writing, there is only 1 supported backend API (Activity Platform),
	 * so we can immediately mark the event for eviction.
	 *
	 * This eviction is "lazy", meaning that the event is *marked* as evicted & filtered from the
	 * result set, but not actually removed from memory until `trackLocalActivity` is called.
	 */
	const markAsConsistent = useCallback((contentId: string) => {
		contentIdsToBeEvicted.add(contentId);
	}, []);

	const result: LocalActivity = {
		viewedEvents: [],
		deletedContentIds: new Set<string>(),
		markAsConsistent,
	};

	const allEvents = data?.localActivity || [];
	allEvents.forEach((event) => {
		if (!shouldIncludeEvent(event, filters)) {
			return;
		}

		if (event.action === LocalActivityAction.viewed) {
			result.viewedEvents.push(event);
		} else if (event.action === LocalActivityAction.deleted) {
			result.deletedContentIds.add(event.content.id || '');
		}
	});

	return result;
};

const shouldIncludeEvent = (event: LocalActivityEvent, filters?: LocalActivityFilters) => {
	// Filter events which will be evicted
	if (isMarkedForEviction(event)) {
		return false;
	}

	if (!filters) {
		return true;
	}

	return filters.spaceKey === event.content.space?.key;
};

type TrackOptions = {
	contentId: string;
	action: LocalActivityAction;
};

/**
 * In addition to tracking new events, this function is also responsible for:
 * 1. enforcing the MAX_EVENTS cap
 * 2. actually evicting events from cache after they have been marked for eviction
 */
export const trackLocalActivity = (
	{ contentId, action }: TrackOptions,
	apolloClient: ApolloClient<object>,
) => {
	/**
	 * The Content is dehydrated (i.e. only Content.id is present, not full Content)
	 * because we may not know all Content data at track time.
	 * The full Content will be hydrated from cache automatically when querying in useLocalActivity
	 */
	let dehydratedEvents: DehydratedEvent[] = [];
	try {
		const result = apolloClient.readQuery<DehydratedResult>({
			query: DehydratedLocalActivityQuery,
		});
		dehydratedEvents =
			result?.localActivity.filter((e) => !isMarkedForEviction(e)).slice(0, MAX_EVENTS - 1) || [];
	} catch {}

	contentIdsToBeEvicted.clear();

	const data: DehydratedResult = {
		localActivity: [
			{
				id: `${contentId}-${action}`,
				action,
				timestamp: new Date().toISOString(),
				content: {
					id: contentId,
					__typename: 'Content',
				},
				__typename: 'LocalActivityEvent',
			},
			...dehydratedEvents,
		],
	};
	apolloClient.writeQuery({
		query: DehydratedLocalActivityQuery,
		data,
	});
};

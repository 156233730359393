import { useCallback, useEffect, useRef } from 'react';
import set from 'lodash/set';
import { useQuery } from 'react-apollo';
import { NetworkStatus } from 'apollo-client';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useSessionData } from '@confluence/session-data';

import type { LocalActivity } from '../useLocalActivity';
import { useLocalActivity } from '../useLocalActivity';
import type { MergeResult } from '../MergeFunction';

import type {
	LocalActivityMyVisitsQuery,
	LocalActivityMyVisitsQuery as MyVisitsQueryData,
	LocalActivityMyVisitsQueryVariables as MyVisitsQueryVars,
	LocalActivityMyVisitsQuery_activities_myActivities_viewed_edges as MyVisitsEdge,
} from './__types__/MyVisitsQuery';
import { MyVisitsQuery } from './MyVisitsQuery.graphql';
import { mergeMyVisits } from './mergeMyVisits';

const EVENT_EXPIRATION_SECONDS = 60;

export type UseMyVisitsOptions = {
	spaceKey: string;
	pageSize?: number;
	skip?: boolean;
	fetchPolicy?: 'cache-first' | 'cache-and-network' | 'network-only';
};

export type MyVisitsData = {
	edges: MyVisitsEdge[];
	hasNextPage: boolean;
};

const useMerge = (
	remoteEdges: MyVisitsEdge[],
	localActivity: LocalActivity,
	shouldMerge: boolean,
): Partial<MergeResult<MyVisitsEdge>> => {
	if (!shouldMerge) {
		return {};
	}
	return mergeMyVisits(remoteEdges, localActivity, {
		expirationSeconds: EVENT_EXPIRATION_SECONDS,
	});
};

const useRefetch = (
	apolloRefetch: () => void,
	loading: boolean,
	remoteEdges: MyVisitsEdge[],
	localActivity: LocalActivity,
	consistentContentIds?: Set<string>,
	latestExpiration?: Date,
) => {
	const didRefetch = useRef(false);
	const prevLatestExpiration = useRef<Date | undefined>();

	const refetch = useCallback(() => {
		didRefetch.current = true;
		apolloRefetch();
	}, [apolloRefetch]);

	// Analytics & mark events as consistent when backend catches up
	const { createAnalyticsEvent } = useAnalyticsEvents();
	useEffect(() => {
		if (didRefetch.current && !loading && consistentContentIds) {
			didRefetch.current = false;
			consistentContentIds.forEach((contentId: string) => {
				localActivity.markAsConsistent(contentId);
			});

			const localActivityCount = localActivity.viewedEvents.length;
			const newlyConsistentCount = consistentContentIds.size;
			const inconsistencyCount = localActivityCount - consistentContentIds.size;
			const activityPlatformCount = remoteEdges.length;
			createAnalyticsEvent({
				type: 'sendOperationalEvent',
				data: {
					actionSubject: 'useMyVisits',
					action: 'refetched',
					source: 'spaceViews',
					attributes: {
						localActivityCount,
						newlyConsistentCount,
						inconsistencyCount,
						activityPlatformCount,
					},
				},
			}).fire();
		}
	});

	// Refetch on event expiration
	useEffect(() => {
		if (
			latestExpiration &&
			(!prevLatestExpiration.current || latestExpiration > prevLatestExpiration.current)
		) {
			prevLatestExpiration.current = latestExpiration;
			refetch();
		}
	});

	return refetch;
};

// Activity platform no longer stores UGC.
// This means we need to map the ccgraphql hydrated title to the, now missing, object name.
// Please see:
//   - https://hello.atlassian.net/browse/COMMIT-3691
//   - https://product-fabric.atlassian.net/wiki/spaces/YW/pages/3390835304/LDR+De-risking+Consumer+Migration+w.r.t.+Confluence
const transformActivityPlatformLegacyUgcRemovalMitigationResponse = (
	activitiesData: LocalActivityMyVisitsQuery | undefined,
) =>
	activitiesData?.activities?.myActivities?.viewed?.edges === undefined
		? activitiesData
		: ({
				...activitiesData,
				activities: {
					...activitiesData.activities,
					myActivities: {
						...activitiesData.activities?.myActivities,
						viewed: {
							...activitiesData.activities?.myActivities?.viewed,
							edges: [...(activitiesData.activities?.myActivities?.viewed?.edges ?? [])].map(
								(edge) => ({
									...edge,
									node: {
										...edge?.node,
										object: {
											...edge?.node?.object,
											name: edge?.node?.object?.content?.title,
										},
									},
								}),
							),
						},
					},
				},
			} as LocalActivityMyVisitsQuery);

const useQueryActivityPlatform = ({
	skip,
	pageSize = 25,
	spaceKey,
	fetchPolicy = 'cache-first',
}: UseMyVisitsOptions) => {
	const { cloudId } = useSessionData();

	const { data, loading, fetchMore, error, refetch, networkStatus } = useQuery<
		MyVisitsQueryData,
		MyVisitsQueryVars
	>(MyVisitsQuery, {
		skip,
		variables: {
			first: pageSize,
			spaceId: getSpaceId(spaceKey, cloudId),
			after: '',
			cloudId,
		},
		fetchPolicy,
		notifyOnNetworkStatusChange: true,
	});

	return {
		data: transformActivityPlatformLegacyUgcRemovalMitigationResponse(data),
		loading,
		fetchMore,
		error,
		refetch,
		networkStatus,
	};
};

/**
 * This hook:
 *   1) Queries Activity Platform
 *   2) Queries Local Activity
 *   3) Merges the results of 1 & 2
 * It will also refetch after a local event "expires" and will mark
 * any newly-consistent content IDs as such.
 */
export const useMyVisits = (options: UseMyVisitsOptions) => {
	const { spaceKey, skip } = options;

	const {
		data: remoteData,
		loading,
		fetchMore,
		error,
		refetch: apolloRefetch,
		networkStatus,
	} = useQueryActivityPlatform(options);

	const remoteEdges = (remoteData?.activities?.myActivities?.viewed?.edges || []).filter(
		inSpaceAndNotNull(spaceKey),
	);

	const hasNextPage =
		Boolean(remoteData?.activities?.myActivities?.viewed?.pageInfo.hasNextPage) &&
		remoteEdges.length > 0;
	const after = remoteEdges[remoteEdges.length - 1]?.cursor;

	const nextPage = useCallback(async () => {
		if (!hasNextPage || !after) {
			return;
		}
		await fetchMore({
			variables: {
				after,
			},
			updateQuery: (prevResult: MyVisitsQueryData, { fetchMoreResult }) => {
				if (!fetchMoreResult) {
					return prevResult;
				}
				const result = { ...fetchMoreResult };
				const prevEdges = prevResult.activities?.myActivities?.viewed?.edges || [];
				const fetchMoreEdges = fetchMoreResult.activities?.myActivities?.viewed?.edges || [];
				set(result, 'activities.myActivities.viewed.edges', [...prevEdges, ...fetchMoreEdges]);
				return result;
			},
		});
	}, [fetchMore, hasNextPage, after]);

	const localActivity = useLocalActivity({
		skip,
		filters: { spaceKey },
	});

	const shouldMerge = !skip && networkStatus !== NetworkStatus.loading && Boolean(remoteData);
	const { mergedNodes, latestExpiration, consistentContentIds } = useMerge(
		remoteEdges,
		localActivity,
		shouldMerge,
	);
	const data: MyVisitsData | undefined = mergedNodes && {
		edges: mergedNodes,
		hasNextPage,
	};

	const refetch = useRefetch(
		apolloRefetch,
		loading,
		remoteEdges,
		localActivity,
		consistentContentIds,
		latestExpiration,
	);

	return {
		data,
		loading,
		error,
		nextPage,
		refetch,
	};
};

const inSpaceAndNotNull =
	(spaceKey: string) =>
	(edge: MyVisitsEdge | null): edge is MyVisitsEdge =>
		Boolean(edge && edge.node?.object?.content?.space?.key === spaceKey);

/*
 * Get the spaceID used to filter Activity Platform results
 * ContainerIDs are a Base64 encoded strings of the form:
 * ari:cloud:confluence:{cloudId}:space/${spaceSlug}
 * Where spaceSlug is a hex string of the form: vi/{cloudId}/{spaceKey}
 */
const getSpaceId: (spaceKey: string, cloudId: string) => string = (spaceKey, cloudId) => {
	const hexEncodedSpace = Buffer.from(`v1/${cloudId}/${spaceKey}`, 'ascii').toString('hex');
	return btoa(`ari:cloud:confluence:${cloudId}:space/${hexEncodedSpace}`);
};
